import React from "react"

import Layout from "../components/Layout"
import Page from "../components/Layout/page"

const TermsOfUse = props => (
  <Layout location={props.location}>
    <Page id="14" />
  </Layout>
)

export default TermsOfUse
